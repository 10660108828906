import React from 'react';
import { graphql } from 'gatsby';
import { Nav, NavItem, NavLink, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, useIntl } from 'gatsby-plugin-intl';

import { Layout, SEO, Image } from '../../components';
import countries from '../../constants/countries';
import messages from '../../messages/community';
import coverPic from '../../assets/images/community-header.png';

const MembersPage = ({
  params: { memberFields__country: countryCode },
  data: {
    allMembers: { edges: members }
  }
}) => {
  const intl = useIntl();
  const countryName =
    countries.find(
      country => country[countryCode.toString().toUpperCase()] !== undefined
    )[countryCode] || '';

  return (
    <Layout fluid background={false} pure>
      <SEO title={intl.formatMessage(messages.community)} />
      <Row
        className="mx-0 p-O community-header mb-5"
        style={{ backgroundImage: `url(${coverPic})` }}
      >
        <div className="d-flex align-items-center w-100 justify-content-center">
          <div className="text-center community-header-title">
            {intl.formatMessage(messages.headerQuote)}
          </div>
        </div>
      </Row>
      <div className="px-5 container">
        <Row>
          <Col>
            <div className="activities-tabs-and-search mb-5">
              <Nav tabs className="activities-tabs">
                <NavItem>
                  <NavLink active className="community-nav-link">
                    {intl.formatMessage(messages.community)}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="community-country-country-name">{countryName}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="community-country-lorem mb-5">
              Lorem ipsum where is brian simple dummy kitchen in the house
              printing and typesetting industry. Lorem Ipsum has
            </div>
          </Col>
        </Row>
        <Row>
          {members.map(({ node: member }) => (
            <div
              className="d-flex justify-content-center w-25 mb-5"
              key={member.id}
            >
              <Link to={`/member/${member.slug}`}>
                <div className="community-country-member-card">
                  <Image
                    src={member.avatar?.url}
                    width={200}
                    height={200}
                    alt={member.name}
                    className=""
                  />
                  <div className="community-country-member-card-name-box">
                    {member.name}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Row>
      </div>
    </Layout>
  );
};
MembersPage.propTypes = {
  data: PropTypes.shape({
    allMembers: PropTypes.shape({
      edges: PropTypes.arrayOf({
        node: PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string,
          memberFields: PropTypes.shape({
            country: PropTypes.string
          }),
          avatar: PropTypes.shape({
            url: PropTypes.string
          })
        })
      })
    })
  }).isRequired
};
export default MembersPage;
export const query = graphql`
  query($memberFields__country: String!) {
    allMembers: allWpUser(
      filter: {
        memberFields: { country: { eq: $memberFields__country } }
        slug: { ne: "admin" }
      }
    ) {
      edges {
        node {
          id
          slug
          name
          memberFields {
            country
          }
          avatar {
            url
          }
        }
      }
    }
  }
`;
